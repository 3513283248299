<template>
	<div class="rtc-container">
		<div class="yogain_head_itemR_outBorder">
			<div class="yogain_head_itemR">
				<!-- this.status_sta=2//1准备中 2直播中 已下课 -->
				<div
					:class="[{'yogain_head_itemR_status1':status_sta==1},{'yogain_head_itemR_status2':status_sta==2},{'yogain_head_itemR_status3':status_sta==3},'yogain_head_itemR_status']">
					{{status_txt}}</div>
				<div class="yogain_head_itemR_cutdown">{{djs_txt}}</div>
				<img @click="switchCoursesPopup()" class="yogain_head_itemR_icon"
					src="https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1660621239.png" />
			</div>
			<div @click="flashPage()" class="yogain_head_itemR_outBorder_flash">
				<img class="yogain_head_itemR_outBorder_flash_img"
					src="https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1665741508.png" />
				重新加载
			</div>
		</div>
		<!-- 显示邀请链接 -->
		<div v-show="false" class="invite-link-container">
			<span v-if="isEnLang">Copy the link to invite friends to join the video call, one link can invite only one
				person,
				the link will be updated automatically after copying.</span>
			<span v-else>一个链接只能一个人使用，如果分享给多人，那需要多复制几次链接并分享。复制链接后，链接会动更新的</span>
			<el-input class="invite-input" v-model="inviteLink">
				<template slot="prepend">
					<el-tooltip :visibleArrow="false" effect="dark" content="Copied!" placement="bottom" :manual="true"
						v-model="showCopiedTip">
						<span class="invite-btn" @click="handleCopyInviteLink">
							<svg-icon icon-name="copy"></svg-icon>
						</span>
					</el-tooltip>
				</template>
			</el-input>
		</div>
		<!-- Log 展示区域 -->
		<div v-if="isHostMode" class="log-container" style="height: 0;display: none;" ref="logContainer"></div>
		<!-- 主播模块 -->
		<div class="coach_block">
			<van-row>
				<van-col span="8">
					<div v-if="is_authorize==1" :class="[{'coach_itemP':big_ot_index!=1000},'coach_item']">
						<div :class="[{'local-stream-contentMy':big_ot_index==1000},'coach_item_id']" id="localStream">
						</div>
						<div :class="[{'coach_item_id_coachS':big_ot_index==1000},'coach_item_id_coach']">
							【教练】{{coach_name}}</div>
					</div>
					<div v-else :class="[{'coach_itemP':big_ot_index!=1000},'coach_item']">
						<div :class="[{'local-stream-contentMy':big_ot_index==1000},'coach_item_id']"
							@click="showOpenMyPanel()" id="localStream"></div>
						<div :class="[{'coach_item_id_coachS':big_ot_index==1000},'coach_item_id_coach']">
							【教练】{{coach_name}}</div>
					</div>
				</van-col>
				<van-col span="8" v-for="(ui_item,ui_index) in user_list" :key="ui_index+'a'">
					<div class="coach_item">
						<div :class="[{'coach_item_idP':big_ot_index==-1||big_ot_index==999},'coach_item_id']">
							<div @click="showBigFullPageName(ui_index)" v-if="ui_item.name">
								<div v-for="(item,index) in remoteStreamList" :key="item.getUserId()+'c'">
									<div @click="showBigFullPage(index)" :id="item.getUserId()"
										v-if="ui_item.is_user_type==1 && item.getUserId()==ui_item.sign_user_id"
										:class="[{'network_big_windowT_itemS':index==big_ot_index},'network_big_windowT_item']"
										class="">
									</div>
								</div>
								<!-- 
									big_ot_index:-1,//-1初始化 999:主播  1000:代表自己  其他数字代表不同的用户
								 -->
								<div :class="[{'coach_item_id_userS':ui_index_index==ui_index},'coach_item_id_user']">
									{{ui_item.name}}</div>
								<div class="coach_item_avatar" :style="'background-image: url('+ui_item.avatar+');'">
									<img class="coach_item_avatar_notonline"
										src="../../src/assets/image/outline_img.png" />
								</div>
							</div>
						</div>
					</div>
				</van-col>
			</van-row>

			<!-- <div>
				<button @click="flashPage()">刷新页面</button>
			</div> -->
		</div>
		<!-- <div >
			<div v-for="(item,index) in remoteStreamList" :key="item.getUserId()+'b'"  class="network_big_window" >
				<div :id="item.getUserId()" class="network_big_windowT_item"  ></div>
			</div>
		</div> -->
		<div class="info-container">

			<!-- 
				big_ot_index:-1,//-1初始化 999:主播  1000:代表自己  其他数字代表不同的用户
			 -->
			<!-- 远端流 -->
			<!-- <div :class="[{'remote-container_position':big_ot_index==-1||big_ot_index==999||big_ot_index==1000},'remote-container']">
				<div v-for="(ui_item,ui_index) in user_list" :key="item" >
					<div class="network_big_windowB_out_out">
						<div class="network_big_window_outline">
							<div class="network_big_window_outline_empty" style="color: black;">
								<img class="network_big_window_outline_empty_icon" src="https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1663048726.png" />
							</div>
						</div>
						<div v-for="(item,index) in remoteStreamList" :key="item.getUserId()" @click="showBigFullPage(index)"   :class="[{'network_big_windowT':index==default_index},{'network_big_windowB':index==big_ot_index},'network_big_window']" >
							<div :id="item.getUserId()" v-if="ui_item.is_user_type==1 && item.getUserId()==ui_item.sign_user_id" :class="[{'network_big_windowT_itemS':index==big_ot_index},'network_big_windowT_item']" class="" ></div>
						</div>
					</div>
				</div>
				<div v-if="remoteStreamList.length>0 &&courses_type==1" @click="switchPanel()" class="remote-container_replace">替换</div>
				<div v-if="remoteStreamList.length>0 &&courses_type==4" @click="switchPanel()" class="remote-container_replace">替换</div>
			</div> -->



			<!-- 本地流区域 -->
			<div class="local-stream-container">
				<!-- status_sta=1//1准备中 2直播中 已下课 -->
				<!-- <div v-if="status_sta==1" @click="showOpenMyPanel()" id="localStream" :class="[{'local-stream-contentMy':big_ot_index==1000},'local-stream-content']"></div>
				<div v-else-if="status_sta==2"  @click="showOpenMyPanel()" id="localStream" :class="[{'local-stream-contentMy':big_ot_index==1000},'local-stream-content']"></div>
				<div v-else  @click="showOpenMyPanel()" id="localStream" :class="[{'local-stream-contentMy':big_ot_index==1000},'local-stream-content']"></div> -->
			</div>
		</div>
		<!-- 页面下方操作按钮 -->
		<div class="yogain_bottom">
			<div v-if="is_authorize==1" @click="flashPage()" class="yogain_bottom_left">
				<div class="yogain_bottom_left_item yogain_bottom_left_item_vioce"
					style="background-image: url(https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1660630474.png);">
				</div>
				<div class="yogain_bottom_left_item yogain_bottom_left_item_video"
					style="background-image: url(https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1660630554.png);">
				</div>
				<div class="yogain_bottom_left_item yogain_bottom_left_item_camer"
					style="background-image: url(https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1660630696.png);">
				</div>
			</div>
			<div v-else class="yogain_bottom_left">
				<div v-if="isMutedAudio" @click="unmuteAudio"
					class="yogain_bottom_left_item yogain_bottom_left_item_vioce"
					style="background-image: url(https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1660630474.png);">
				</div>
				<div v-if="!isMutedAudio" @click="muteAudio"
					class="yogain_bottom_left_item yogain_bottom_left_item_vioce"
					style="background-image: url(https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1660630629.png);">
				</div>
				<div v-if="isMutedVideo" @click="unmuteVideo"
					class="yogain_bottom_left_item yogain_bottom_left_item_video"
					style="background-image: url(https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1660630554.png);">
				</div>
				<div v-if="!isMutedVideo" @click="muteVideo"
					class="yogain_bottom_left_item yogain_bottom_left_item_video"
					style="background-image: url(https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1660630656.png);">
				</div>
				<div v-if="camm_index==0" @click="switchCamera(1)"
					class="yogain_bottom_left_item yogain_bottom_left_item_camer"
					style="background-image: url(https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1660630696.png);">
				</div>
				<div v-else @click="switchCamera(0)" class="yogain_bottom_left_item yogain_bottom_left_item_camer"
					style="background-image: url(https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1660630696.png);">
				</div>
			</div>
			<!-- 
		   <div class="video-control control">
		   	<span v-if="!isMutedVideo" @click="muteVideo">
		   		<svg-icon icon-name="video" class="icon-class"></svg-icon>
		   	</span>
		   	<span v-if="isMutedVideo" @click="unmuteVideo">
		   		<svg-icon icon-name="video-muted" class="icon-class"></svg-icon>
		   	</span>
		   </div>
		   <div class="audio-control control">
		   	<span v-if="!isMutedAudio" @click="muteAudio">
		   		<svg-icon icon-name="audio" class="icon-class"></svg-icon>
		   	</span>
		   	<span v-if="isMutedAudio" @click="unmuteAudio">
		   		<svg-icon icon-name="audio-muted" class="icon-class"></svg-icon>
		   	</span>
		   </div>
		   -->
			<div class="yogain_bottom_center">
				<div @click="overTimeCourses()" class="yogain_bottom_right" style="margin-right: 10px;">课程结束</div>
				<div v-if="ts_type==1" class="yogain_bottom_right">
					<div v-if="courses_type==1||courses_type==4">
						<div v-if="user_list.length>0" @click="switchPanel()">
							人数
							<!-- 团课才显示 -->
							<div class="yogain_bottom_right_num">{{user_list.length}}</div>
						</div>
						<div v-else>
							人数
						</div>
					</div>
				</div>

			</div>

		</div>
		<div class="empty_seat"></div>
		<!-- 课程已结束popup -->
		<van-popup v-model="overtime_courses_popup_show" :close-on-click-overlay="false" round
			style="background: #fff;padding: 30px 30px;width: 60%;">
			<div class="" style="width: 100%;text-align: center;color: #000000;">
				课程已经结束
			</div>
		</van-popup>
		<!-- 课程信息 -->
		<van-popup v-model="courses_popup_show" close-icon-position="top-left" closeable round position="bottom"
			style="background: #fff;" :style="{ height: '50%' }">
			<div class="yogain_popup" style="">
				<div class="yogain_popup_title">课程信息</div>
				<div class="yogain_popup_conte yogain_popup_conte_padding">
					<van-row>
						<van-col span="6">
							<div class="yogain_popup_courses_title">当前课程</div>
						</van-col>
						<van-col span="18">
							<div class="yogain_popup_courses_panel">{{sd_course_info.sd_course_name}}</div>
						</van-col>
						<van-col span="6">
							<div class="yogain_popup_courses_title">教练</div>
						</van-col>
						<van-col span="18">
							<div class="yogain_popup_courses_panel">{{sd_course_info.coach_name}}</div>
						</van-col>
						<van-col span="6">
							<div class="yogain_popup_courses_title">时间</div>
						</van-col>
						<van-col span="18">
							<div class="yogain_popup_courses_panel">
								{{sd_course_info.start_time}}-{{sd_course_info.end_time_short}}</div>
						</van-col>
					</van-row>
				</div>
			</div>
		</van-popup>
		<!-- 用户列表 -->
		<van-popup v-model="user_popup_show" :safe-area-inset-bottom="safe_area_inset_bottom" closeable round
			position="bottom">
			<div class="yogain_popup">
				<div class="yogain_popup_title">正在观看</div>
				<div class="yogain_popup_conte">
					<div v-for="(y_item,y_index) in user_list" class="yogain_popup_item">
						<div @click="showCorrespondPanel(y_index,y_item)" class="yogain_popup_item_left">
							<img v-if="y_item.avatar" :src="y_item.avatar" />
							<div v-else class="yogain_popup_item_left_emptyicon"></div>
							{{y_item.name?y_item.name:y_item.mobile}}
						</div>
						<div class="yogain_popup_item_right">
							<div v-if="userEventCallBack.length>0">
								<div v-for="(ul_item,ul_index) in userEventCallBack">
									<div v-if="y_item.sign_user_id==ul_item.event_info.userId">
										<div v-if="ul_item.event_info.eventType==101 || ul_item.event_info.eventType==103">
											<div class="yogain_popup_item_right_item">
												<!-- 在线 -->
												<img class="yogain_popup_item_right_item_online" src="https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1666754708.png" />
												<!-- 踢人按钮 -->
												<img @click="deleteUser(y_item,y_index)" class="yogain_popup_item_right_item_delete" src="https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1666160127.png" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<!--
								离线 https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1666754397.png
								在线 https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1666754708.png
							-->
							<!-- <div v-if="userEventCallBack.length==0" style="display: flex;justify-content: center;align-items: center;">
						已离线
					</div>
					<div v-else>
						<div v-for="(ul_item,ul_index) in userEventCallBack">
							<div v-if="y_item.sign_user_id==ul_item.event_info.userId">
								<div v-if="ul_item.event_info.eventType==101 || ul_item.event_info.eventType==103" style="display: flex;justify-content: center;align-items: center;">
									在线
									<img @click="deleteUser(y_item,y_index)" src="https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1666160127.png" />
								</div>
								<div v-else  style="display: flex;justify-content: center;align-items: center;">
									已离线
								</div>
							</div>
						</div>
					</div> -->

						</div>
					</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>
<script>
	import rtc from './mixins/rtc.js';
	import shareRtc from './mixins/share-rtc.js';
	import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
	import {
		getDaysAndHours
	} from '../utils/utils.js'

	export default {
		name: 'compRoom',
		mixins: [rtc, shareRtc],
		props: {
			type: String,
			sdkAppId: Number,
			secretKey: String,
			userId: String,
			roomId: Number,
			cameraId: String,
			microphoneId: String,
			inviteUserSig: String,
			courses_type: Number,
			scheduleId: Number,
			user_id_short: String,
			day: String,
			coach_name: String,
			ts_type: Number
		},
		data() {
			return {
				logList: [],
				inviteLink: '',
				showCopiedTip: false,
				networkBigWindow: 'VC',
				djs_txt: '00:00:00',
				status_sta: 1,
				status_txt: '',
				courses_popup_show: false, //课程面板
				user_popup_show: false,

				default_index: -1, //代表其他学员面板层级
				big_ot_index: -1, //-1初始化 999:主播  1000:代表自己  其他数字代表不同的用户

				user_list: [], //用户列表
				sd_course_info: {}, //课程信息
				safe_area_inset_bottom: true, //安全区域


				userinfo: {}, //用户

				ui_index_index: -1, //初始化
				// coach_live_roome.type  1团课  私教


				userEventCallBack: [], //用户在直播间的行为

				overtime_courses_popup_show: false,

				is_authorize: 0, //是否授权麦克风、摄像头  0初始状态  1拒绝  2允许
			};
		},
		mounted() {
			// this.djs()
			var $this = this

			setTimeout(function() {
				$this.FLLoadStadiumList()
			}, 1000);
		},
		computed: {
			isHostMode() {
				return this.type !== 'Home';
			},
			isEnLang() {
				return this.$i18n.locale === 'en';
			},
			showInviteLink() {
				return this.isHostMode && (this.isJoined || this.isShareJoined) && this.inviteLink;
			},
		},
		watch: {
			cameraId(val) {
				this.switchDevice('video', val);
			},
			microphoneId(val) {
				this.switchDevice('audio', val);
			},
		},
		methods: {
			// 结束课程
			dissCoursesRoom() {
				var $this = this
				let coach_live_roome = JSON.parse(sessionStorage.getItem('coach_live_roome'))
				let live_room_param = JSON.parse(sessionStorage.getItem('live_room_param'))

				console.log(coach_live_roome)
				console.log(live_room_param)
				var param = {
					schedule_id: coach_live_roome.schedule_id,
					type: coach_live_roome.type,
					access_token: coach_live_roome.access_token,
					room_id: live_room_param.room_num
				}
				console.log(param)

				var qs = require('qs');
				let url = this.api.userApi.dissCoursesRoom
				this.axios.post(url, qs.stringify(param), )
					.then((result) => {
						console.log(result)
						if (result.code == 200) {
							let url = window.location.href
							// http://coach.yogain.cn/index/login/login
							// https://test.coach.yogain.cn/
							// if(url.indexOf('192')>=0 ||url.indexOf('https://test')>=0){
							// 	window.location.href='https://test.coach.yogain.cn'
							// }else{
							// 	window.location.href='http://coach.yogain.cn'
							// }
							// $this.overtime_courses_popup_show=true
						}
					}).catch((error) => {
						console.warn(error)
					})
			},
			overTimeCourses() {

				// dissCoursesRoom
				var $this = this

				this.$dialog.confirm({
						title: '温馨提示',
						message: '是否结束课程？',
					})
					.then(() => {
						// on confirm
						$this.dissCoursesRoom()
					})
					.catch(() => {
						// on cancel
					});

				return



				/*
				access_token	yes	string	effxxx	登录标识
				schedule_id	yes	int	1901	课程id
				type	yes	int	1	1团课 2私教
				room_id	yes	int	10004	房间号
				*/
				/*
				let coach_live_roome=UrlParamparse(coach_live_roome_str)
				let live_room_param=UrlParamparse(live_room_param_str)
				
				console.log(coach_live_roome)
				console.log(live_room_param)
				console.log('----页面参数----');
				console.log(coach_live_roome)
				console.log(live_room_param)
				
				
				// console.log(this.$route);
				// console.log(this.$route.query);
				
				console.log('----页面参数----');
				// this.sdkAppId = Number(getUrlParam('sdkAppId'));//sdkAppId固定在前端，不带在参数中
				this.userSig = live_room_param.user_sign//getUrlParam('userSig');
				this.userId =live_room_param.video_uid// getUrlParam('userId');
				this.roomId =live_room_param.room_num// Number(getUrlParam('roomId'));
				this.courses_type=Number(coach_live_roome.type)//getUrlParam('courses_type');
				this.schedule_id=Number(coach_live_roome.schedule_id)//getUrlParam('schedule_id');
				this.coach_name=coach_live_roome.coach_name
				// this.user_id_short=getUrlParam('user_id_short');
				this.day=coach_live_roome.day//getUrlParam('day');
				this.ts_type=Number(coach_live_roome.type)
				*/
				// this.overtime_courses_popup_show=true
			},
			FLLoadStadiumList() {
				var $this = this
				let param = {
					schedule_id: this.scheduleId,
					type: this.courses_type,
					day: this.day
				}
				console.log('--api传参--')
				console.log(param)
				console.log('--api传参--')
				// return
				/*
					/api/TenXunYunAudio/getScheduleByOrder
					schedule_id 课程id
					type 1 团课 2私教
				*/
				var qs = require('qs');
				let url = this.api.userApi.getScheduleByOrder
				this.axios.post(url, qs.stringify(param), )
					.then((result) => {
						if (result.code == 200) {
							console.log('result.data')
							console.log(result.data)
							console.log('result.data')
							// return
							// 剔除教练
							if (result.data.length > 0) {
								for (var i = 0; i < result.data.length; i++) {
									if (!result.data[i].mobile) {
										(result.data).splice(i, 1)
										i--
									} else {
										console.log(result.data[i].avatar)
										if (!result.data[i].avatar) {
											result.data[i].avatar =
												'https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1663819365.png'
										}
									}
								}
							}
							console.log('result.dataXXX')
							console.log(result.data)
							$this.user_list = result.data
							result.data[0].end_time_short = (result.data[0].end_time).substring(11, 16)
							$this.sd_course_info = result.data[0]
							console.log(result.data[0])
							$this.djs_time(result.data[0].start_time, result.data[0].end_time)
							$this.realTimeGetInfo() //
						}
					}).catch((error) => {
						console.warn(error)
					})
			},
			// 计时
			djs_time(start_t, end_t) {
				console.log('时间戳=' + start_t, end_t)
				var $this = this
				// 倒计时
				this.cut_time_down(start_t, end_t)
				// 进入房间
				this.handleJoinRoom()
				return
				/**
				 * 准备中倒计时
				 */
				let start_time = start_t + ':00'; //'2022-08-22 16:46:00'//开课时间
				let start_time_temp = new Date(start_time.replace(/-/g, '/')).getTime() //开课时间戳
				let cur_time_temp = new Date().getTime() //当前时间戳
				let end_time = end_t + ':00'; //'2022-08-22 16:47:00'//下课时间
				let end_time_temp = new Date(end_time.replace(/-/g, '/')).getTime() //下课时间戳
				if (cur_time_temp < start_time_temp) { // 准备中
					this.status_txt = '准备中'
					this.status_sta = 1 //1准备中 2直播中 已下课
					clearInterval($this.timel)
					this.timel = setInterval(() => {
						let last = getDaysAndHours(start_time_temp - new Date().getTime())
						// console.log(last)
						if (start_time_temp - new Date().getTime() <= 0) { //倒计时结束，需要重新计算倒计时
							$this.djs_txt = '00:00:00'
							clearInterval($this.timel)
							$this.djs_time()
							$this.flashPage()
						} else {
							let ho = Number(last.hour) + Number(last.day) * 24 + Number(last.month) * 30 * 24 +
								Number(last.year) * 365 * 24
							let hour = ho < 10 ? '0' + ho : ho
							let minute = last.minute < 10 ? '0' + last.minute : last.minute
							let second = last.second < 10 ? '0' + last.second : last.second
							$this.djs_txt = hour + ':' + minute + ':' + second
						}
					}, 1000)
				} else if (cur_time_temp > end_time_temp) { // 加时中
					this.status_txt = '加时中'
					this.status_sta = 3 //1准备中 2直播中 已下课
					clearInterval($this.timel)
					this.timel = setInterval(() => {
						let last = getDaysAndHours(new Date().getTime() - end_time_temp)
						// console.log(last)
						let ho = Number(last.hour) + Number(last.day) * 24 + Number(last.month) * 30 * 24 + Number(
							last.year) * 365 * 24
						let hour = ho < 10 ? '0' + ho : ho
						let minute = last.minute < 10 ? '0' + last.minute : last.minute
						let second = last.second < 10 ? '0' + last.second : last.second
						$this.djs_txt = hour + ':' + minute + ':' + second
					}, 1000)
				} else { // 直播中
					this.status_txt = '直播中'
					this.status_sta = 2 //1准备中 2直播中 已下课
					clearInterval($this.timel)
					this.timel = setInterval(() => {
						let last = getDaysAndHours(end_time_temp - new Date().getTime())
						// console.log(last)
						if (end_time_temp - new Date().getTime() <= 0) { //倒计时结束，需要重新计算倒计时
							clearInterval($this.timel)
							$this.djs_time()
						} else {
							let ho = Number(last.hour) + Number(last.day) * 24 + Number(last.month) * 30 * 24 +
								Number(last.year) * 365 * 24
							let hour = ho < 10 ? '0' + ho : ho
							let minute = last.minute < 10 ? '0' + last.minute : last.minute
							let second = last.second < 10 ? '0' + last.second : last.second
							$this.djs_txt = hour + ':' + minute + ':' + second
						}
					}, 1000)
				}

			},
			cut_time_down(start_t, end_t) {
				// start_t='2022-09-23 10:29'
				var $this = this
				/**
				 * 准备中倒计时
				 */
				let start_time = start_t + ':00'; //'2022-08-22 16:46:00'//开课时间
				let start_time_temp = new Date(start_time.replace(/-/g, '/')).getTime() //开课时间戳
				let cur_time_temp = new Date().getTime() //当前时间戳
				let end_time = end_t + ':00'; //'2022-08-22 16:47:00'//下课时间
				let end_time_temp = new Date(end_time.replace(/-/g, '/')).getTime() //下课时间戳
				if (cur_time_temp < start_time_temp) { // 准备中
					this.status_txt = '准备中'
					this.status_sta = 1 //1准备中 2直播中 已下课
					clearInterval($this.timel)
					this.timel = setInterval(() => {
						let last = getDaysAndHours(start_time_temp - new Date().getTime())
						// console.log(last)
						if (start_time_temp - new Date().getTime() <= 0) { //倒计时结束，需要重新计算倒计时
							$this.djs_txt = '00:00:00'
							clearInterval($this.timel)
							$this.cut_time_down(start_t, end_t)
						} else {
							let ho = Number(last.hour) + Number(last.day) * 24 + Number(last.month) * 30 * 24 +
								Number(last.year) * 365 * 24
							ho = parseInt(ho, 10)
							let hour = ho < 10 ? '0' + ho : ho
							let minute = last.minute < 10 ? '0' + last.minute : last.minute
							let second = last.second < 10 ? '0' + last.second : last.second
							$this.djs_txt = hour + ':' + minute + ':' + second
						}
					}, 1000)
				} else if (cur_time_temp > end_time_temp) { // 加时中
					this.status_txt = '加时中'
					this.status_sta = 3 //1准备中 2直播中 已下课
					clearInterval($this.timel)
					this.timel = setInterval(() => {
						let last = getDaysAndHours(new Date().getTime() - end_time_temp)
						// console.log(last)
						let ho = Number(last.hour) + Number(last.day) * 24 + Number(last.month) * 30 * 24 + Number(
							last.year) * 365 * 24
						ho = parseInt(ho, 10)
						let hour = ho < 10 ? '0' + ho : ho
						let minute = last.minute < 10 ? '0' + last.minute : last.minute
						let second = last.second < 10 ? '0' + last.second : last.second
						$this.djs_txt = hour + ':' + minute + ':' + second
					}, 1000)
				} else { // 直播中
					this.status_txt = '直播中'
					this.status_sta = 2 //1准备中 2直播中 已下课
					clearInterval($this.timel)
					this.timel = setInterval(() => {
						let last = getDaysAndHours(end_time_temp - new Date().getTime())
						// console.log(last)
						if (end_time_temp - new Date().getTime() <= 0) { //倒计时结束，需要重新计算倒计时
							clearInterval($this.timel)
							$this.cut_time_down(start_t, end_t)
						} else {
							let ho = Number(last.hour) + Number(last.day) * 24 + Number(last.month) * 30 * 24 +
								Number(last.year) * 365 * 24
							ho = parseInt(ho, 10)
							let hour = ho < 10 ? '0' + ho : ho
							let minute = last.minute < 10 ? '0' + last.minute : last.minute
							let second = last.second < 10 ? '0' + last.second : last.second
							$this.djs_txt = hour + ':' + minute + ':' + second
						}
					}, 1000)
				}
			},
			generateInviteLink() {
				if (!this.isHostMode) {
					return;
				}
				const {
					sdkAppId,
					secretKey,
					roomId
				} = this;
				const inviteUserId = `YOGAIN_${parseInt(Math.random() * 100000000, 10)}`;
				const userSigGenerator = new LibGenerateTestUserSig(sdkAppId, secretKey, 604800);
				const inviteUserSig = userSigGenerator.genTestUserSig(inviteUserId);
				this.inviteLink = encodeURI(
					`${location.origin}${location.pathname}#/invite?sdkAppId=${sdkAppId}&userSig=${inviteUserSig}&roomId=${roomId}&userId=${inviteUserId}`
				);
			},
			handleCopyInviteLink() {
				const $this = this;
				navigator.clipboard.writeText(this.inviteLink);
				this.showCopiedTip = true;
				this.$copyText(this.inviteLink).then(() => {
					setTimeout(() => {
						$this.showCopiedTip = false;
					}, 1500);
					// this.$message.success({message:"已成功复制到剪切板"});
				}).catch(() => {
					this.showCopiedTip = false;
					// this.$message.error({message:"复制失败"});
				});
				this.generateInviteLink();
			},
			// 点击【Join Room】按钮
			async handleJoinRoom() {
				if (this.isHostMode) {
					// 主播
					if (!this.sdkAppId || !this.secretKey) {
						alert(this.$t('Please enter sdkAppId and secretKey'));
						return;
					}
					if (!this.userId || !this.roomId) {
						alert(this.$t('Please enter userId and roomId'));
						return;
					}
					const userSigGenerator = new LibGenerateTestUserSig(this.sdkAppId, this.secretKey, 604800);
					this.userSig = userSigGenerator.genTestUserSig(this.userId);
				} else {
					// 观众
					if (!this.sdkAppId || !this.inviteUserSig || !this.userId || !this.roomId) {
						alert(this.$t('Please reacquire the invitation link'));
						return;
					}
					this.userSig = this.inviteUserSig;
				}
				await this.initClient();
				const roomId = this.roomId;
				if (this.isHostMode) {
					// 主播
					await this.joinanchor();
				} else {
					// 观众
					await this.join();
				}
				await this.initLocalStream();
				await this.playLocalStream();
				await this.publish();
				this.generateInviteLink();
				for (let i = 0; i < 10; i++) {
					await this.publish();
				}
			},
			// 点击【Publish】按钮
			async handlePublish() {
				await this.publish();
			},
			// 点击【Unpublish】按钮
			async handleUnpublish() {
				await this.unPublish();
			},
			// 点击【Leave Room】按钮
			async handleLeave() {
				await this.leave();
			},
			// 点击【开始屏幕分享】按钮
			async handleStartScreenShare() {
				if (!this.sdkAppId || !this.secretKey) {
					alert(this.$t('Please enter sdkAppId and secretKey'));
					return;
				}
				await this.initShareClient();
				await this.initShareLocalStream();
				await this.handleShareJoin();
				await this.handleSharePublish();
				this.generateInviteLink();
			},
			// 点击【停止屏幕分享按钮】
			async handleStopScreenShare() {
				await this.handleShareUnpublish();
				await this.handleShareLeave();
			},
			// 显示成功的 Log
			addSuccessLog(log) {
				console.log('addSuccessLog')
				console.log(log)
				console.log('addSuccessLog')
				// if (!this.isHostMode) {
				// 	return;
				// }
				// this.logList.push({
				// 	type: 'success',
				// 	log,
				// });
				// const {
				// 	scrollHeight
				// } = this.$refs.logContainer;
				// this.$refs.logContainer.scrollTop = scrollHeight;
			},
			// 显示失败的 Log
			addFailedLog(log) {
				var $this=this
				console.log('addFailedLog')
				if (log && log.indexOf('0x1004') >= 0) {
					console.log('LocalStream.initialize() 采集失败')
					$this.is_authorize = 1
				} else if (log && log.indexOf('0x1003') >= 0) {
					console.log('LocalStream.initialize() 设备不支持')
					$this.is_authorize = 1
				}
				console.log('addFailedLog')
				// if (!this.isHostMode) {
				// 	return;
				// }
				// this.logList.push({
				// 	type: 'failed',
				// 	log,
				// });
				// const {
				// 	scrollHeight
				// } = this.$refs.logContainer;
				// this.$refs.logContainer.scrollTop = scrollHeight;
			},
			reportSuccessEvent(name) {
				this.$aegis.reportEvent({
					name,
					ext1: `${name}-success`,
					ext2: 'webrtcQuickDemoVue2',
					ext3: this.sdkAppId,
				});
			},
			reportFailedEvent(name, error, type = 'rtc') {
				console.log('reportFailedEvent')
				console.log(name)
				console.log(error)
				console.log(type)
				console.log('reportFailedEvent')
				// this.$aegis.reportEvent({
				// 	name,
				// 	ext1: `${name}-failed#${this.roomId}*${type === 'share' ? this.shareUserId : this.userId}*${error.message}`,
				// 	ext2: 'webrtcQuickDemoVue2',
				// 	ext3: this.sdkAppId,
				// });
			},
			// 切换课程信息面板
			switchCoursesPopup() {
				this.courses_popup_show = !this.courses_popup_show
			},
			// 切换用户列表面板
			switchPanel() {
				this.user_popup_show = !this.user_popup_show
			},

			// 查看对应学员的视角
			showCorrespondPanel(index, item) {
				// this.switchPanel()//切换用户列表面板
				// this.user_id

				console.log(item)
				console.log('用户自己的id：' + this.user_id_short)
				console.log('点击的id：' + item.user_id)

				if (!item.user_id) {
					console.log('该用户还未进入直播间')
					// this.showBigFullPage(index)
					return
				}

				if (this.user_id_short == item.user_id) {
					console.log('用户点击自己')
					this.showOpenMyPanel()
					return
				}

				console.log(this.remoteStreamList.length)
				for (var i = 0; i < this.remoteStreamList.length; i++) {
					console.log('getUserId()=' + this.remoteStreamList[i].getUserId())
					console.log('getAudioLevel=' + this.remoteStreamList[i].getAudioLevel())
					if (this.remoteStreamList[i].getUserId() == item.sign_user_id) {
						console.log('用户点击对应索引为：' + i + ';对应id=' + item.user_id)
						this.default_index = i
						this.showBigFullPage(i)
						break
					}
				}

			},
			showBigFullPage(index) {
				console.log(index, this.big_ot_index)
				if (this.big_ot_index == index) {
					this.big_ot_index = -1
					this.ui_index_index = -1
				} else {
					console.log('index=' + index)
					this.big_ot_index = index
					console.log('this.big_ot_index=' + this.big_ot_index)
				}
			},
			showBigFullPageName(index) {
				var $this = this
				setTimeout(function() {
					if ($this.big_ot_index != -1) {
						if ($this.ui_index_index == index) {
							$this.ui_index_index = -1
							$this.big_ot_index = -1
						} else {
							$this.ui_index_index = index
						}
					}
				}, 300);

			},
			showOpenMyPanel() {
				if (this.big_ot_index == 1000) {
					this.big_ot_index = -1
				} else {
					this.big_ot_index = 1000
				}

			},
			showBigPanelAtu() {
				if (this.big_ot_index == 999) {
					this.big_ot_index = -1
				} else {
					this.big_ot_index = 999
				}
				// big_ot_index:-1,//-1初始化 999:主播  1000:代表自己  其他数字代表不同的用户
			},

			flashPage() {
				this.$router.go(0)
			},
			// 循环获取用户信息
			realTimeGetInfo() {
				var $this = this
				// 定时返回用户状态
				setInterval(() => {
					$this.realTimeGetInfoAfter()
				}, 5000)
				// 定时返回用户信息
				setInterval(() => {
					$this.realTimeGetUser()
				}, 60000)
			},
			realTimeGetInfoAfter() {
				var $this = this
				let coach_live_roome = JSON.parse(sessionStorage.getItem('coach_live_roome'))
				let live_room_param = JSON.parse(sessionStorage.getItem('live_room_param'))
				var param = {
					schedule_id: coach_live_roome.schedule_id,
					type: coach_live_roome.type,
					day: coach_live_roome.day,
					// sign_user_id:this.userId
				}
				// console.log(param)
				// console.log(live_room_param)
				// console.log(coach_live_roome)
				// return
				var qs = require('qs');
				let url = this.api.userApi.getEventCallBack
				this.axios.post(url, qs.stringify(param), )
					.then((result) => {
						if (result.code == 200) {
							// console.log('setInterval')
							// console.log(result)
							$this.userEventCallBack = result.data
							/*
								EventType
									101:创建房间
									102:解散房间
									103:进入房间
									104:退出房间
									105:切换角色
									201:开始推送视频数据
									202:停止推送视频数据
									203:开始推送音频数据
									204:停止推送音频数据
									205:开始推送辅路数据
									206:停止推送辅路数据
							*/
						}
					}).catch((error) => {
						console.warn(error)
					})
			},
			// 踢人
			deleteUser(y_item, y_index) {
				var $this = this
				this.$dialog.confirm({
						title: '温馨提示',
						message: '是否将该用户请出直播间？',
						// showCancelButton: false
					})
					.then(() => {
						// on confirm
						$this.deleteUserAfter(y_item, y_index)
					})
					.catch(() => {
						// on cancel
					});
			},
			deleteUserAfter(y_item, y_index) {
				console.log('踢人')
				// dissdDissolve
				console.log(y_item, y_index)
				console.log('踢人')
				// let type=Number(y_item.type-3)
				let coach_live_roome = JSON.parse(sessionStorage.getItem('coach_live_roome'))
				var $this = this
				var param = {
					uid: y_item.sign_user_id,
					room_id: y_item.room_no,
					schedule_id: this.scheduleId,
					type: this.courses_type,
					access_token: coach_live_roome.access_token
				}
				console.log(param)
				console.log(this.api.userApi.dissdDissolve)

				console.log(coach_live_roome.access_token)
				// return
				var qs = require('qs');
				let url = this.api.userApi.dissdDissolve
				this.axios.post(url, qs.stringify(param), )
					.then((result) => {
						console.log(result)
						if (result.code == 200) {

						}
					}).catch((error) => {
						console.warn(error)
					})

			},
			realTimeGetUser() {
				var $this = this
				let param = {
					schedule_id: this.scheduleId,
					type: this.courses_type,
					day: this.day
				}
				console.log(param)
				// return
				/*
					/api/TenXunYunAudio/getScheduleByOrder
					schedule_id 课程id
					type 1 团课 2私教
				*/
				var qs = require('qs');
				let url = this.api.userApi.getScheduleByOrder
				this.axios.post(url, qs.stringify(param), )
					.then((result) => {
						if (result.code == 200) {
							if (result.data.length > 0) {
								for (var i = 0; i < result.data.length; i++) {
									if (!result.data[i].mobile) {
										(result.data).splice(i, 1)
										i--
									} else {
										console.log(result.data[i].avatar)
										if (!result.data[i].avatar) {
											result.data[i].avatar =
												'https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1663819365.png'
										}
									}
								}
							}
							$this.user_list = result.data
						}
					}).catch((error) => {
						console.warn(error)
					})
			}
		},
	};
</script>
<style lang="scss" scoped>
	$vm_base:1/750*100vw;

	video {
		width: 100%;
	}

	.empty_seat {
		width: 100%;
		height: 160*$vm_base;
	}

	.rtc-container {
		width: 100%;
		position: relative;
		// padding-bottom: 160*$vm_base;
		height: 100vh;
		overflow: hidden;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}

		.yogain_head_itemR_outBorder {
			width: 100%;
			position: relative;
			height: 98*$vm_base;
		}

		.yogain_head_itemR {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 1299;
			background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.2) 100%);
			width: 100%;
			height: 98*$vm_base;
			font-size: 32*$vm_base;
			font-weight: 500;
			color: #FFFFFF;
			display: flex;
			justify-content: center;
			align-items: center;

			.yogain_head_itemR_status {
				padding: 0 14*$vm_base;
				height: 40*$vm_base;
				border-radius: 4*$vm_base;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 24*$vm_base;
				font-weight: 400;
				color: #FFFFFF;
				margin-right: 16*$vm_base;
			}

			.yogain_head_itemR_status1 {
				background: rgba(245, 108, 21, 0.4000);
			}

			.yogain_head_itemR_status2 {
				background: rgba(21, 245, 62, 0.4000);
			}

			.yogain_head_itemR_status3 {
				background: rgba(245, 190, 21, 0.4000);
			}

			.yogain_head_itemR_cutdown {
				height: 40*$vm_base;
				font-size: 26*$vm_base;
				font-weight: 400;
				color: #FFFFFF;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.yogain_head_itemR_icon {
				width: 32*$vm_base;
				height: 32*$vm_base;
				margin-left: 16*$vm_base;
			}
		}

		.label {
			margin: 14*$vm_base 0 6*$vm_base;
			text-align: left;
			font-weight: bold;
		}

		.control-container {
			text-align: left;
			margin-bottom: 10*$vm_base;

			div:not(:nth-last-child(1)) {
				margin-bottom: 10*$vm_base;
			}

			.button:not(:first-child) {
				margin-left: 2*$vm_base;
			}
		}

		.invite-link-container {
			width: 100%;
			color: #084298;
			background-color: #cfe2ff;
			position: relative;
			padding: 10*$vm_base 16*$vm_base;
			margin-bottom: 16*$vm_base;
			border: 1*$vm_base solid #b6d4fe;
			border-radius: 0.25rem;

			.invite-input {
				margin-top: 10*$vm_base;
			}

			.invite-btn {
				display: flex;
				cursor: pointer;
			}
		}

		.local-stream-contentM_posi {
			position: relative;
			width: 100%;
			height: 386*$vm_base;
			display: flex;
			justify-content: center;
			align-items: center;
			background: rgba(255, 255, 255, 0.1);

			.local-stream-contentM_posi_img {
				width: 160*$vm_base;
				height: 160*$vm_base;
				border-radius: 50%;
			}

			.local-stream-contentM_posi_txt {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				text-align: center;
				font-size: 24*$vm_base;
				font-weight: 500;
				color: #FFFFFF;
				margin-bottom: 30*$vm_base;
			}
		}

		.local-stream-contentM_outborder {
			width: 100%;
			height: 386*$vm_base;
			background: rgba(255, 255, 255, 0.1);
		}

		.local-stream-contentM_outborder_posi {
			position: relative;
		}

		.local-stream-contentM_outborder_posi_item {
			width: 100%;
			height: 386*$vm_base;
		}

		.info-container {
			width: 100%;
			display: flex;
			justify-content: space-between;

			.local-stream-contentM {
				width: 100%;
				height: 386*$vm_base;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
			}

			.local-stream-contentM_posiIN {
				width: 100%;
				height: 386*$vm_base;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				background: rgba(255, 255, 255, 0.1);

				display: flex;
				justify-content: center;
				align-items: center;

				.local-stream-contentM_posi_img {
					width: 160*$vm_base;
					height: 160*$vm_base;
					border-radius: 50%;
				}

				.local-stream-contentM_posi_txt {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					text-align: center;
					font-size: 24*$vm_base;
					font-weight: 500;
					color: #FFFFFF;
					margin-bottom: 30*$vm_base;
				}
			}

			.local-stream-contentM_big {
				position: fixed;
				top: 0;
				left: 0;
				z-index: 999;
				width: 100%;
				height: 100vh;
			}

			.log-container {
				flex-grow: 1;
				padding: 10*$vm_base;
				margin-right: 16*$vm_base;
				overflow-y: auto;

				.log-label {
					margin: 0 0 6*$vm_base;
					font-weight: bold;
				}

				.log-state {
					display: inline-block;
					margin-right: 6*$vm_base;
				}

				>div {
					font-size: 12*$vm_base;
				}
			}

			.local-stream-container {
				width: calc(50% - 10*$vm_base);
				height: 720*$vm_base;
				position: relative;
				flex-shrink: 0;

				.local-stream-content {
					width: 100%;
					height: 100%;
				}

				.local-stream-contentMy {
					position: fixed;
					top: 0;
					left: 0;
					z-index: 999;
					width: 100%;
					height: 100vh;
				}

				.local-stream-control {
					width: 100%;
					height: 30*$vm_base;
					position: absolute;
					bottom: 0;
					background-color: rgba(0, 0, 0, 0.3);
					display: flex;
					justify-content: flex-end;
					align-items: center;
					padding: 0 10*$vm_base;

					.control {
						margin-left: 10*$vm_base;
					}

					.icon-class {
						color: #fff;
						cursor: pointer;
						width: 40*$vm_base;
						height: 40*$vm_base;
					}
				}
			}
		}

		.info-container-mobile {
			display: block;

			.log-container {
				margin-right: 0;
			}

			.local-stream-container {
				width: 100%;
				height: 720*$vm_base;
			}
		}

		.remote-container_position {
			position: relative;
		}

		.remote-container {
			width: calc(50% - 10*$vm_base);
			display: flex;
			flex-wrap: wrap;

			background: rgba(255, 255, 255, 0.1);

			.network_big_window_outline {
				position: absolute;
				top: 0;
				left: 0;

				width: 100%;
				height: 720*$vm_base;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.network_big_window_outline_empty {
				width: 136*$vm_base;
				height: 136*$vm_base;
				border-radius: 50%;
				background-color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;

				.network_big_window_outline_empty_icon {
					width: 119*$vm_base;
				}
			}

			.network_big_window {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 98;
				width: 100%;
				height: 720*$vm_base;
			}

			.network_big_windowT_item {
				height: 100%;
				width: 100%;
			}

			.network_big_windowT_itemS {
				height: 100vh;
			}

			.network_big_windowT {
				z-index: 99;
			}

			.network_big_windowB {
				position: fixed;
				top: 0;
				left: 0;
				z-index: 999;
				width: 100%;
				height: 100vh;
			}

			.network_big_windowB_out_out {
				width: 100%;
				height: 720*$vm_base;
			}

			.network_big_windowB_out {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 720*$vm_base;
			}

			.remote-stream-container {
				width: 50*$vm_base;
				height: 50*$vm_base;
				margin: 0 10*$vm_base 10*$vm_base 0;
				border-radius: 50%;
				overflow: hidden;

				div {
					width: 50*$vm_base;
					height: 50*$vm_base;
				}
			}
		}

		.remote-container_replace {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 100;
			font-size: 22*$vm_base;
			font-weight: 400;
			color: #FFFFFF;
			margin-top: 28*$vm_base;
			width: 70*$vm_base;
			height: 40*$vm_base;
			background: rgba(0, 0, 0, 0.3);
			border-radius: 0 100*$vm_base 100*$vm_base 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.cr_audience {
			width: 100%;
			margin-bottom: 20*$vm_base;
		}

	}

	.yogain_bottom {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 1299;
		width: 100%;
		height: 160*$vm_base;
		// background: rgba(0,0,0,0.7000);
		padding: 20*$vm_base 32*$vm_base 0 32*$vm_base;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.yogain_bottom_left {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			.yogain_bottom_left_item {
				width: 80*$vm_base;
				height: 80*$vm_base;
				border-radius: 50%;
				margin-right: 20*$vm_base;
				background-position: center;
				background-repeat: no-repeat;
				background-size: 100% 100%;
			}

			.yogain_bottom_left_item_vioce {}

			.yogain_bottom_left_item_video {}

			.yogain_bottom_left_item_camer {}
		}

		.yogain_bottom_right {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			width: 132*$vm_base;
			height: 80*$vm_base;
			background: rgba(0, 0, 0, 0.3000);
			border-radius: 40*$vm_base;
			border: 1*$vm_base solid rgba(255, 255, 255, 0.5000);
			font-size: 24*$vm_base;
			font-weight: 500;
			color: #FFFFFF;

			.yogain_bottom_right_num {
				width: 48*$vm_base;
				height: 30*$vm_base;
				background: #DD6767;
				border-radius: 40*$vm_base;
				font-size: 20*$vm_base;
				color: #FFFFFF;
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				top: 0;
				right: 0;
				margin-top: -4*$vm_base;
			}
		}
	}

	.yogain_popup {
		width: 100%;
		padding: 0 32*$vm_base;
		.yogain_popup_title {
			width: 100%;
			font-size: 32*$vm_base;
			font-weight: 500;
			color: #000000;
			text-align: center;
			padding: 40*$vm_base 0 52*$vm_base 0;
		}
		.yogain_popup_conte_padding {
			padding: 16*$vm_base 0 68*$vm_base 0;
		}
		.yogain_popup_conte {
			width: 100%;
			.yogain_popup_courses_title {
				width: 100%;
				height: 40*$vm_base;
				font-size: 28*$vm_base;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				font-weight: 500;
				color: #A7A7A7;
				margin-bottom: 20*$vm_base;
			}
			.yogain_popup_courses_panel {
				width: 100%;
				height: 40*$vm_base;
				font-size: 28*$vm_base;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				font-weight: 500;
				color: #0A0A0A;
				margin-bottom: 20*$vm_base;
			}
			.yogain_popup_item {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 30*$vm_base;
				.yogain_popup_item_left {
					font-size: 28*$vm_base;
					font-weight: 500;
					color: #666666;
					display: flex;
					justify-content: flex-start;
					align-items: center;

					img {
						width: 72*$vm_base;
						height: 72*$vm_base;
						border-radius: 50%;
						margin-right: 16*$vm_base;
					}

					.yogain_popup_item_left_emptyicon {
						width: 72*$vm_base;
						height: 72*$vm_base;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 50%;
						background: #F3F3F3;
						margin-right: 16*$vm_base;
						background-image: url(https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1661913104.png);
						background-size: calc(100% - 10*$vm_base) auto;
						background-repeat: no-repeat;
						background-position: center;
					}
				}
				.yogain_popup_item_right {
					width: 150*$vm_base;
					height: 72*$vm_base;
					font-size: 24*$vm_base;
					font-weight: 500;
					color: #999999;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					background-image: url(https://yoga-union.oss-cn-shanghai.aliyuncs.com/resource/1666754397.png);
					background-position: right center;
					background-repeat: no-repeat;
					background-size: 72*$vm_base auto;
					.yogain_popup_item_right_item{
						width: 150*$vm_base;
						height: 72*$vm_base;
						background-color: #fff;
						display: flex;
						justify-content: flex-end;
						align-items: center;
						.yogain_popup_item_right_item_online{
							width: 72*$vm_base;
						}
						.yogain_popup_item_right_item_delete{
							width: 48*$vm_base;
							height: 48*$vm_base;
							margin-left: 12*$vm_base;
						}
					}
					// img {
					// 	width: 48*$vm_base;
					// 	height: 48*$vm_base;
					// 	margin-left: 12*$vm_base;
					// }
				}
			}
		}
	}
	.coach_block {
		width: 100%;
		padding: 0 27*$vm_base;

		.coach_itemP {
			position: relative;
		}

		.coach_item {
			padding: 0 5*$vm_base;
			width: 100%;
			margin-bottom: 10*$vm_base;
			display: flex;
			justify-content: center;
			align-items: center;

			.coach_item_id {
				width: 100%;
				height: 365*$vm_base;
				background: rgba(255, 255, 255, 0.1);
				display: flex;
				justify-content: center;
				align-items: center;

				.network_big_windowT_item {
					height: 365*$vm_base;
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
				}

				.network_big_windowT_itemS {
					height: 100vh;
					width: 100%;
					position: fixed;
					top: 0;
					left: 0;
					z-index: 999;
				}

				.coach_item_id_user {
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 1;
					width: 100%;
					font-size: 24*$vm_base;
					font-weight: 500;
					color: #FFFFFF;
					text-align: center;
					margin-bottom: 10*$vm_base;
				}

				.coach_item_id_userS {
					position: absolute;
					top: 0;
					margin-top: 98*$vm_base;
					left: 0;
					z-index: 1000;
					height: 72*$vm_base;
					background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 51%, rgba(0, 0, 0, 0) 100%);
					font-size: 24*$vm_base;
					display: flex;
					justify-content: center;
					align-items: center;
					font-weight: 500;
					color: #FFFFFF;
				}
			}

			.local-stream-contentMy {
				position: fixed;
				top: 0;
				left: 0;
				z-index: 999;
				width: 100%;
				height: 100vh;
			}

			.coach_item_id_coach {
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 1;
				width: 100%;
				font-size: 24*$vm_base;
				font-weight: 500;
				color: #FFFFFF;
				text-align: center;
				margin-bottom: 10*$vm_base;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.coach_item_id_coachS {
				position: fixed;
				top: 0;
				margin-top: 98*$vm_base;
				left: 0;
				z-index: 1000;
				height: 72*$vm_base;
				background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 51%, rgba(0, 0, 0, 0) 100%);
				font-size: 24*$vm_base;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: 500;
				color: #FFFFFF;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.coach_item_idP {
				position: relative;
			}

			.coach_item_avatar {
				width: 96*$vm_base;
				height: 96*$vm_base;
				border-radius: 50%;
				background-position: center;
				background-size: 99% 99%;
				background-repeat: no-repeat;
			}

			.coach_item_avatar_notonline {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}
	}
	.yogain_head_itemR_outBorder_flash {
		position: fixed;
		top: 0;
		right: 0;
		z-index: 1300;
		height: 98*$vm_base;
		display: flex;
		padding-right: 27*$vm_base;
		justify-content: center;
		align-items: center;
		font-size: 24*$vm_base;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 1;
		white-space: nowrap;

		.yogain_head_itemR_outBorder_flash_img {
			height: 28*$vm_base;
			margin-right: 8*$vm_base;
		}
	}
	.yogain_bottom_center {
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>

<i18n>
	{
	"en": {
	"Operation": "Operation",
	"Join Room": "Join Room",
	"Publish": "Publish",
	"Unpublish": "Unpublish",
	"Leave Room": "Leave Room",
	"Start Screen Share": "Start Screen Share",
	"Stop Screen Share": "Stop Screen Share",
	"Please enter sdkAppId and secretKey": "Please enter sdkAppId and secretKey",
	"Please enter userId and roomId": "Please enter userId and roomId",
	"Please reacquire the invitation link": "Please reacquire the invitation link!"
	},
	"zh": {
	"Operation": "操作",
	"Join Room": "进入房间",
	"Publish": "发布流",
	"Unpublish": "取消发布流",
	"Leave Room": "离开房间",
	"Start Screen Share": "开始共享屏幕",
	"Stop Screen Share": "停止共享屏幕",
	"Please enter sdkAppId and secretKey": "请输入 sdkAppId 和 secretKey",
	"Please enter userId and roomId": "请输入 userId 和 roomId",
	"Please reacquire the invitation link": "请重新获取邀请链接！"
	}
	}
</i18n>
